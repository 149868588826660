export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39')
];

export const server_loads = [];

export const dictionary = {
		"/": [4],
		"/admin/analytics": [5],
		"/admin/city-collections": [6],
		"/admin/city-collections/[id]": [7],
		"/admin/curated-list-block": [8],
		"/admin/editorials": [9],
		"/admin/editorials/[id]": [10],
		"/admin/featured-carousel": [11],
		"/admin/featured-collections": [12],
		"/admin/featured-collections/[offerable_type]/[id]": [13,[2]],
		"/admin/home-explorable-collections": [14],
		"/admin/home-explorable-collections/[id]": [15,[3]],
		"/admin/listing": [16],
		"/admin/membership-settings": [17],
		"/admin/mobile-app-config": [18],
		"/admin/partners": [19],
		"/admin/partners/[id]": [20],
		"/admin/snackable-questions": [21],
		"/admin/staff": [22],
		"/admin/volunteers": [23],
		"/admin/volunteers/[id]": [24],
		"/auth-verification/[token]": [25],
		"/form_submissions": [26],
		"/login": [27],
		"/offers/deals/[id]": [28],
		"/offers/events/[id]": [29],
		"/offers/places/[id]": [30],
		"/partner/dashboard": [31],
		"/partner/profile": [32],
		"/partner/socials": [33],
		"/partner/staff": [34],
		"/signup": [35],
		"/volunteer-opportunities": [38],
		"/volunteer-opportunities/[id]": [39],
		"/volunteer/dashboard": [36],
		"/volunteer/profile": [37]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';